import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.defnemarket.com/', 
  //baseURL: 'http://localhost:8080/',
  withCredentials: true, // This ensures that cookies are sent
});


export default instance;
