import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Image, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { listProductDetails, updateProduct } from '../actions/productActions';
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants';
import axios from 'axios';
import FormContainer from '../components/FormContainer';
import './ProductEditScreen.css';
import axiosInstance from '../actions/axiosInstance'
import ImageWithFallback from '../components/ImageWithFallback';

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id;

  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState('');
  const [images, setImages] = useState([]);
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState('');
  const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState('');
  
  // New state for image previews
  const [mainImagePreview, setMainImagePreview] = useState('');
  const [tempMainImage, setTempMainImage] = useState(null);
  const [tempImages, setTempImages] = useState([]);
  const [imagesPreviews, setImagesPreviews] = useState([]);
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = productUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      history.push('/admin/productlist');
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(listProductDetails(productId));
      } else {
        setName(product.name);
        setPrice(product.price);
        setImage(product.image);
        setBrand(product.brand);
        setCategory(product.category);
        setCountInStock(product.countInStock);
        setDescription(product.description);
        // Set image previews from existing images
        setMainImagePreview(product.image);
        setImagesPreviews(product.images || []);
      }
    }
  }, [dispatch, history, productId, product, successUpdate]);

  const uploadFileHandler = (e, isMain = true) => {
    const file = e.target.files[0];
    if (file) {
      if (isMain) {
        setTempMainImage(file);
        setMainImagePreview(URL.createObjectURL(file));
      } else {
        setTempImages([...tempImages, file]);
        setImagesPreviews([...imagesPreviews, URL.createObjectURL(file)]);
      }
    }
  };

  const removeImage = (index) => {
    const newTempImages = [...tempImages];
    const newImagesPreviews = [...imagesPreviews];
    newTempImages.splice(index, 1);
    newImagesPreviews.splice(index, 1);
    setTempImages(newTempImages);
    setImagesPreviews(newImagesPreviews);
  };

  const uploadImages = async () => {
    try {
      setUploading(true)
      
      if (!userInfo) {
        throw new Error('Please login first')
      }
      
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      // Upload main image if exists
      if (tempMainImage) {
        const mainFormData = new FormData()
        mainFormData.append('images', tempMainImage)
        const { data: mainImageUrl } = await axiosInstance.post('/api/upload', mainFormData, config)
        // Set the full S3 URL directly
        setImage(mainImageUrl[0]) // This will be the full S3 URL
      }

      // Upload additional images
      if (tempImages.length > 0) {
        const uploadedUrls = []
        for (const file of tempImages) {
          const formData = new FormData()
          formData.append('images', file)
          const { data: imageUrl } = await axiosInstance.post('/api/upload', formData, config)
          // Store the full S3 URL
          uploadedUrls.push(imageUrl[0])
        }
        setImages(uploadedUrls)
      }

      setUploading(false)
      return true
    } catch (error) {
      console.error('Upload error:', error)
      setUploading(false)
      throw error
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      let mainImageUrl = image
      let additionalImages = images

      // Upload images first if there are any new ones
      if (tempMainImage || tempImages.length > 0) {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }

        setUploading(true)

        // Upload main image if exists
        if (tempMainImage) {
          const mainFormData = new FormData()
          mainFormData.append('images', tempMainImage)
          const { data } = await axiosInstance.post('/api/upload', mainFormData, config)
          mainImageUrl = data[0] // Store S3 URL directly
        }

        // Upload additional images
        if (tempImages.length > 0) {
          const uploadedUrls = []
          for (const file of tempImages) {
            const formData = new FormData()
            formData.append('images', file)
            const { data } = await axiosInstance.post('/api/upload', formData, config)
            uploadedUrls.push(data[0])
          }
          additionalImages = uploadedUrls
        }

        setUploading(false)
      }

      // Now dispatch with the actual URLs
      dispatch(
        updateProduct({
          _id: productId,
          name,
          price,
          image: mainImageUrl,
          images: additionalImages,
          brand,
          category,
          description,
          countInStock,
        })
      )
    } catch (error) {
      console.error('Submit error:', error)
    }
  }

  return (
    <>
      <Link to='/admin/productlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId='price'>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter price'
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId='image'>
              <Form.Label>Main Image</Form.Label>
              {mainImagePreview && (
                <div className="image-preview mb-2">
                  <Image src={mainImagePreview} alt="Preview" thumbnail style={{ height: '100px' }} />
                </div>
              )}
              <Form.Control
                type='file'
                onChange={(e) => uploadFileHandler(e, true)}
              />
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId='additionalImages'>
              <Form.Label>Additional Images</Form.Label>
              <div className="images-preview-container mb-2">
                {imagesPreviews.map((preview, index) => (
                  <div key={index} className="preview-image-container">
                    <Image src={preview} alt={`Preview ${index + 1}`} thumbnail style={{ height: '100px' }} />
                    <Button 
                      variant="danger" 
                      size="sm" 
                      onClick={() => removeImage(index)}
                      className="remove-image-btn"
                    >
                      ×
                    </Button>
                  </div>
                ))}
              </div>
              <Form.Control
                type='file'
                onChange={(e) => uploadFileHandler(e, false)}
              />
            </Form.Group>

            <Form.Group controlId='brand'>
              <Form.Label>Brand</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter brand'
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId='countInStock'>
              <Form.Label>Count In Stock</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter countInStock'
                value={countInStock}
                onChange={(e) => setCountInStock(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId='category'>
              <Form.Label>Category</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter category'
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder='Enter description'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>

            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ProductEditScreen;
