// HeroSection.js
import React, { useEffect } from 'react';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { listTopProducts } from '../../actions/productActions';
import { Link } from 'react-router-dom';
import './hero.css';
import Loader from '../Loader';
import Message from '../Message';

const HeroSection = () => {
  const dispatch = useDispatch();
  const productTopRated = useSelector((state) => state.productTopRated);
  const { loading, error, products } = productTopRated;

  useEffect(() => {
    dispatch(listTopProducts());
  }, [dispatch]);

  return (
    <div className="hero-section">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Carousel pause='hover' className='hero-carousel'>
          {products.map((product) => (
            <Carousel.Item key={product._id}>
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="text-content">
                    <p className="top-seller">TOP RATED PRODUCT</p>
                    <h1>{product.name}</h1>
                    <p className="description">
                      {product.description.length > 75 ? `${product.description.substring(0, 50)}...` : product.description}
                    </p>
                    <Link to={`/product/${product._id}`} className="hero-link">
                      <Button variant="success" className="shop-now-btn">
                        Shop Now - ${product.price}
                      </Button>
                    </Link>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="hero-image-wrapper">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="hero-image"
                    />
                  </div>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default HeroSection;
